import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["expirationLabel", 
                    "expirationInput", 
                    "unlimitedButton", 
                    "percentageInput", 
                    "percentageBlock",
                    "fixedInput",
                    "fixedBlock",
                    "fixedButton",
                    "availabilityButton",
                    "availabilityLabel"]

  toggleType() {
    if (this.fixedButtonTarget.checked) {
      this.percentageInputTarget.required = false
      this.percentageBlockTarget.classList.remove("flex")
      this.percentageBlockTarget.classList.add("hidden")
      this.fixedInputTarget.required = true
      this.fixedBlockTarget.classList.remove("hidden")
      this.fixedBlockTarget.classList.add("flex")
    } else {
      this.fixedInputTarget.required = false
      this.fixedBlockTarget.classList.remove("flex")
      this.fixedBlockTarget.classList.add("hidden")
      this.percentageInputTarget.required = true
      this.percentageBlockTarget.classList.remove("hidden")
      this.percentageBlockTarget.classList.add("flex")
    }
  }

  toggleDate() {
    if (this.unlimitedButtonTarget.checked) {
      this.expirationInputTarget.disabled = true
      this.expirationLabelTarget.innerText = "Fecha de expiración"
      this.expirationInputTarget.required = false
    } else {
      this.expirationInputTarget.disabled = false
      this.expirationLabelTarget.innerText = "Fecha de expiración *"
      this.expirationInputTarget.required = true
    }
  }

  toggleAvailability() {
    if (this.availabilityButtonTarget.checked) {
      this.availabilityLabelTarget.innerText = "Disponible"
    } else {
      this.availabilityLabelTarget.innerText = "No disponible"
    }
  }

}