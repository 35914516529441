import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["index"]
  
  connect() {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    const position = event.newIndex + 1
    const id = event.item.dataset.id
    let url
    
    if (this.hasIndexTarget) {
      url = `/admin/deliveries/${id}/update_position`
    } else {
      url = `/admin/delivery_items/${id}/update_position`
    }


    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        position: position
      })
    })
  }
}