import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "orderItemTemplate",
    "mealProductTemplate",
    "snackProductTemplate",
    "dessertProductTemplate",
    "drinkProductTemplate",
    "dessertExtraTemplate",
    "drinkExtraTemplate",
    "dinnerExtraTemplate",
    "categoryButton",
    "newProductButton",
    "deleteProductButton",
    "includeDinner",
  ];

  newProduct() {
    const id = new Date().valueOf();
    const newOrderItem = this.orderItemTemplateTarget.innerHTML.replace(
      /TEMPLATE_ID/g,
      id
    );

    this.newProductButtonTarget.insertAdjacentHTML("beforebegin", newOrderItem);

    const formBlock = document.querySelector(`[data-id="${id}"]`);

    this.currentForm = formBlock.querySelector(`[data-plans-form-id="${id}"]`);

    formBlock.classList.remove("invisible", "opacity-0");
    formBlock.classList.add("opacity-1", "visible");
  }

  close(event) {
    const id = event.currentTarget.dataset.id;
    const target = document.querySelector(`[data-id="${id}"]`);

    target.classList.remove("opacity-1", "visible");
    target.classList.add("invisible", "opacity-0");
  }

  open(event) {
    const id = event.currentTarget.dataset.productCardId;
    const formBlock = document.querySelector(`[data-id="${id}"]`);

    formBlock.classList.remove("invisible", "opacity-0");
    formBlock.classList.add("opacity-1", "visible");
  }

  toggleCategory(event) {
    const target = event.currentTarget;
    const category = target.dataset.category;
    const id = target.dataset.id;
    const formBlock = document.querySelector(`[data-id="${id}"]`);
    const addProductButton = formBlock.querySelector(
      `[data-add-product-button-id="${id}"]`
    );
    let selectedform;

    this.categoryButtonTargets.forEach((e) => {
      e.classList.remove("bg-primary", "border-primary");
      e.dataset.action = "click->order#toggleCategory";
    });

    target.classList.add("bg-primary", "border-primary");
    target.dataset.action = "";

    switch (category) {
      case "plans":
        selectedform = formBlock.querySelector(`[data-plans-form-id="${id}"]`);
        selectedform.classList.remove("hidden");
        this.currentForm.classList.add("hidden");
        this.currentForm = selectedform;
        addProductButton.dataset.category = category;
        addProductButton.dataset.plan = "Monthly";
        break;
      case "meals":
        selectedform = formBlock.querySelector(`[data-meals-form-id="${id}"]`);
        selectedform.classList.remove("hidden");
        this.currentForm.classList.add("hidden");
        this.currentForm = selectedform;
        addProductButton.dataset.category = category;
        addProductButton.dataset.plan = "";
        break;
      case "snacks":
        selectedform = formBlock.querySelector(`[data-snacks-form-id="${id}"]`);
        selectedform.classList.remove("hidden");
        this.currentForm.classList.add("hidden");
        this.currentForm = selectedform;
        addProductButton.dataset.category = category;
        addProductButton.dataset.plan = "";
        break;
      case "desserts":
        selectedform = formBlock.querySelector(
          `[data-desserts-form-id="${id}"]`
        );
        selectedform.classList.remove("hidden");
        this.currentForm.classList.add("hidden");
        this.currentForm = selectedform;
        addProductButton.dataset.category = category;
        addProductButton.dataset.plan = "";
        break;
      case "drinks":
        selectedform = formBlock.querySelector(`[data-drinks-form-id="${id}"]`);
        selectedform.classList.remove("hidden");
        this.currentForm.classList.add("hidden");
        this.currentForm = selectedform;
        addProductButton.dataset.category = category;
        addProductButton.dataset.plan = "";
        break;
    }
  }

  addProduct(event) {
    const id = event.currentTarget.dataset.addProductButtonId;
    const category = event.currentTarget.dataset.category;
    const formBlock = document.querySelector(`div [data-id="${id}"]`);
    const closeButton = formBlock.querySelector("#close-button");
    const cancelButton = formBlock.querySelector("#cancel-button");
    const addButton = formBlock.querySelector("#add-button");

    let newProductNameValue,
      newProductQuantityValue,
      newProduct,
      newProductSizeValue,
      newProductProteinValue,
      newProductCustomValue,
      newProductExtraVeggiesValue,
      newProductExtraProteinValue,
      newProductExtraCarbsValue,
      extraProducts,
      newDeliveryDate,
      calendarImage;

    this.removeUnusedForms(event);

    switch (category) {
      case "plans":
        newProductNameValue = event.currentTarget.dataset.plan;
        newProductQuantityValue =
          formBlock.querySelector("input[type=number]").value;
        newProductSizeValue = formBlock.querySelector(
          `#order_order_items_attributes_${id}_meal_size`
        ).options[
          formBlock.querySelector(
            `#order_order_items_attributes_${id}_meal_size`
          ).selectedIndex
        ].text;
        newProductProteinValue = formBlock.querySelector(
          `#order_order_items_attributes_${id}_meal_protein`
        ).options[
          formBlock.querySelector(
            `#order_order_items_attributes_${id}_meal_protein`
          ).selectedIndex
        ].text;
        newProductCustomValue = formBlock.querySelector(
          `#order_order_items_attributes_${id}_meal_custom`
        ).options[
          formBlock.querySelector(
            `#order_order_items_attributes_${id}_meal_custom`
          ).selectedIndex
        ].text;
        newProductExtraVeggiesValue = formBlock.querySelector(
          `#order_order_items_attributes_${id}_meal_extra_veggies`
        ).options[
          formBlock.querySelector(
            `#order_order_items_attributes_${id}_meal_extra_veggies`
          ).selectedIndex
        ].text;
        newProductExtraProteinValue = formBlock.querySelector(
          `#order_order_items_attributes_${id}_meal_extra_protein`
        ).options[
          formBlock.querySelector(
            `#order_order_items_attributes_${id}_meal_extra_protein`
          ).selectedIndex
        ].text;
        newProductExtraCarbsValue = formBlock.querySelector(
          `#order_order_items_attributes_${id}_meal_extra_carbs`
        ).options[
          formBlock.querySelector(
            `#order_order_items_attributes_${id}_meal_extra_carbs`
          ).selectedIndex
        ].text;
        newDeliveryDate = formBlock.querySelector("input[type=date]").value;
        calendarImage =
          this.mealProductTemplateTarget.content.querySelector("#calendar");

        this.showCalendar(newDeliveryDate, calendarImage);
        this.mealProductTemplateTarget.content.querySelector(
          ".product-name"
        ).innerHTML = newProductNameValue;
        this.mealProductTemplateTarget.content.querySelector(
          ".product-quantity"
        ).innerHTML = newProductQuantityValue;
        this.mealProductTemplateTarget.content.querySelector(
          ".product-size"
        ).innerHTML = newProductSizeValue;
        if (newProductProteinValue == "Normal") {
          this.mealProductTemplateTarget.content.querySelector(
            ".product-protein"
          ).innerHTML = "";
        } else {
          this.mealProductTemplateTarget.content.querySelector(
            ".product-protein"
          ).innerHTML = newProductProteinValue;
        }
        if (newProductCustomValue == "None") {
          this.mealProductTemplateTarget.content.querySelector(
            ".product-custom"
          ).innerHTML = "";
        } else {
          this.mealProductTemplateTarget.content.querySelector(
            ".product-custom"
          ).innerHTML = newProductCustomValue;
        }
        if (newProductExtraVeggiesValue === "No") {
          this.mealProductTemplateTarget.content.querySelector(
            ".product-extra-veggies"
          ).innerHTML = "";
        } else {
          this.mealProductTemplateTarget.content.querySelector(
            ".product-extra-veggies"
          ).innerHTML = "Extra Veggies";
        }
        if (newProductExtraProteinValue === "No") {
          this.mealProductTemplateTarget.content.querySelector(
            ".product-extra-protein"
          ).innerHTML = "";
        } else {
          this.mealProductTemplateTarget.content.querySelector(
            ".product-extra-protein"
          ).innerHTML = "Extra Protein";
        }
        if (newProductExtraCarbsValue === "No") {
          this.mealProductTemplateTarget.content.querySelector(
            ".product-extra-carbs"
          ).innerHTML = "";
        } else {
          this.mealProductTemplateTarget.content.querySelector(
            ".product-extra-carbs"
          ).innerHTML = "Extra Carbs";
        }

        Array.from(
          formBlock.querySelectorAll(`label[data-id="${id}"]`)
        ).forEach((element) => {
          if (
            element.dataset.planButton === newProductNameValue.toLowerCase()
          ) {
            element.dataset.action = "";
            element.classList.remove("cursor-pointer");
          } else {
            element.parentNode.removeChild(element);
          }
        });
        newProduct = this.mealProductTemplateTarget.innerHTML.replace(
          /TEMPLATE_ID/g,
          id
        );
        break;
      case "meals":
        newProductNameValue =
          formBlock.querySelector("select").options[
            formBlock.querySelector("select").selectedIndex
          ].text;
        newProductQuantityValue =
          formBlock.querySelector("input[type=number]").value;
        newProductSizeValue = formBlock.querySelector(
          `#order_order_items_attributes_${id}_meal_size`
        ).options[
          formBlock.querySelector(
            `#order_order_items_attributes_${id}_meal_size`
          ).selectedIndex
        ].text;
        newProductProteinValue = formBlock.querySelector(
          `#order_order_items_attributes_${id}_meal_protein`
        ).options[
          formBlock.querySelector(
            `#order_order_items_attributes_${id}_meal_protein`
          ).selectedIndex
        ].text;
        newProductCustomValue = formBlock.querySelector(
          `#order_order_items_attributes_${id}_meal_custom`
        ).options[
          formBlock.querySelector(
            `#order_order_items_attributes_${id}_meal_custom`
          ).selectedIndex
        ].text;
        newProductExtraVeggiesValue = formBlock.querySelector(
          `#order_order_items_attributes_${id}_meal_extra_veggies`
        ).options[
          formBlock.querySelector(
            `#order_order_items_attributes_${id}_meal_extra_veggies`
          ).selectedIndex
        ].text;
        newProductExtraProteinValue = formBlock.querySelector(
          `#order_order_items_attributes_${id}_meal_extra_protein`
        ).options[
          formBlock.querySelector(
            `#order_order_items_attributes_${id}_meal_extra_protein`
          ).selectedIndex
        ].text;
        newProductExtraCarbsValue = formBlock.querySelector(
          `#order_order_items_attributes_${id}_meal_extra_carbs`
        ).options[
          formBlock.querySelector(
            `#order_order_items_attributes_${id}_meal_extra_carbs`
          ).selectedIndex
        ].text;

        calendarImage =
          this.mealProductTemplateTarget.content.querySelector("#calendar");

        this.showCalendar("", calendarImage);
        this.mealProductTemplateTarget.content.querySelector(
          ".product-name"
        ).innerHTML = newProductNameValue;
        this.mealProductTemplateTarget.content.querySelector(
          ".product-quantity"
        ).innerHTML = newProductQuantityValue;
        this.mealProductTemplateTarget.content.querySelector(
          ".product-size"
        ).innerHTML = newProductSizeValue;
        if (newProductProteinValue == "Normal") {
          this.mealProductTemplateTarget.content.querySelector(
            ".product-protein"
          ).innerHTML = "";
        } else {
          this.mealProductTemplateTarget.content.querySelector(
            ".product-protein"
          ).innerHTML = newProductProteinValue;
        }
        if (newProductCustomValue == "None") {
          this.mealProductTemplateTarget.content.querySelector(
            ".product-custom"
          ).innerHTML = "";
        } else {
          this.mealProductTemplateTarget.content.querySelector(
            ".product-custom"
          ).innerHTML = newProductCustomValue;
        }
        if (newProductExtraVeggiesValue === "No") {
          this.mealProductTemplateTarget.content.querySelector(
            ".product-extra-veggies"
          ).innerHTML = "";
        } else {
          this.mealProductTemplateTarget.content.querySelector(
            ".product-extra-veggies"
          ).innerHTML = "Extra Veggies";
        }
        if (newProductExtraProteinValue === "No") {
          this.mealProductTemplateTarget.content.querySelector(
            ".product-extra-protein"
          ).innerHTML = "";
        } else {
          this.mealProductTemplateTarget.content.querySelector(
            ".product-extra-protein"
          ).innerHTML = "Extra Protein";
        }
        if (newProductExtraCarbsValue === "No") {
          this.mealProductTemplateTarget.content.querySelector(
            ".product-extra-carbs"
          ).innerHTML = "";
        } else {
          this.mealProductTemplateTarget.content.querySelector(
            ".product-extra-carbs"
          ).innerHTML = "Extra Carbs";
        }

        newProduct = this.mealProductTemplateTarget.innerHTML.replace(
          /TEMPLATE_ID/g,
          id
        );
        break;
      case "snacks":
        newProductNameValue =
          formBlock.querySelector("select").options[
            formBlock.querySelector("select").selectedIndex
          ].text;
        newProductQuantityValue =
          formBlock.querySelector("input[type=number]").value;
        newDeliveryDate = formBlock.querySelector("input[type=date]").value;
        calendarImage =
          this.snackProductTemplateTarget.content.querySelector("#calendar");

        this.showCalendar(newDeliveryDate, calendarImage);
        this.snackProductTemplateTarget.content.querySelector(
          ".product-name"
        ).innerHTML = newProductNameValue;
        this.snackProductTemplateTarget.content.querySelector(
          ".product-quantity"
        ).innerHTML = newProductQuantityValue;

        newProduct = this.snackProductTemplateTarget.innerHTML.replace(
          /TEMPLATE_ID/g,
          id
        );
        break;
      case "desserts":
        newProductNameValue =
          formBlock.querySelector("select").options[
            formBlock.querySelector("select").selectedIndex
          ].text;
        newProductQuantityValue =
          formBlock.querySelector("input[type=number]").value;
        newDeliveryDate = formBlock.querySelector("input[type=date]").value;
        calendarImage =
          this.dessertProductTemplateTarget.content.querySelector("#calendar");

        this.showCalendar(newDeliveryDate, calendarImage);
        this.dessertProductTemplateTarget.content.querySelector(
          ".product-name"
        ).innerHTML = newProductNameValue;
        this.dessertProductTemplateTarget.content.querySelector(
          ".product-quantity"
        ).innerHTML = newProductQuantityValue;

        newProduct = this.dessertProductTemplateTarget.innerHTML.replace(
          /TEMPLATE_ID/g,
          id
        );
        break;
      case "drinks":
        newProductNameValue =
          formBlock.querySelector("select").options[
            formBlock.querySelector("select").selectedIndex
          ].text;
        newProductQuantityValue =
          formBlock.querySelector("input[type=number]").value;
        newDeliveryDate = formBlock.querySelector("input[type=date]").value;
        calendarImage =
          this.drinkProductTemplateTarget.content.querySelector("#calendar");

        this.showCalendar(newDeliveryDate, calendarImage);
        this.drinkProductTemplateTarget.content.querySelector(
          ".product-name"
        ).innerHTML = newProductNameValue;
        this.drinkProductTemplateTarget.content.querySelector(
          ".product-quantity"
        ).innerHTML = newProductQuantityValue;

        newProduct = this.drinkProductTemplateTarget.innerHTML.replace(
          /TEMPLATE_ID/g,
          id
        );
        break;
    }

    this.newProductButtonTarget.insertAdjacentHTML("beforebegin", newProduct);

    const includeDinnerCheckbox = document.getElementById(`dinner-${id}`);

    if (includeDinnerCheckbox && includeDinnerCheckbox.checked) {
      this.addDinner(id, newProductQuantityValue);
    }

    extraProducts = formBlock.querySelectorAll(".extra-form");
    this.addExtra(id, extraProducts, newProductQuantityValue);

    closeButton.dataset.action = "order#close";
    cancelButton.dataset.action = "order#close";
    addButton.dataset.action = "order#updateProduct";
    addButton.textContent = "Actualizar";

    this.categoryButtonTargets.forEach((e) => {
      e.dataset.action = "";
      e.classList.remove("cursor-pointer", "hover:shadow-lg");
    });

    this.close(event);
  }

  togglePlan(event) {
    const target = event.currentTarget;
    const id = target.dataset.id;
    const formBlock = document.querySelector(`div [data-id="${id}"]`);
    const addProductButton = formBlock.querySelector(
      `[data-add-product-button-id="${id}"]`
    );
    const monthlyForm = formBlock.querySelector(
      "[data-plan-form='monthlyForm']"
    );
    const weeklyForm = formBlock.querySelector("[data-plan-form='weeklyForm']");

    if (target.dataset.planButton == "monthly") {
      weeklyForm.classList.remove("flex");
      weeklyForm.classList.add("hidden");
      monthlyForm.classList.remove("hidden");
      monthlyForm.classList.add("flex");
      addProductButton.dataset.plan = "Monthly";
    } else {
      monthlyForm.classList.remove("flex");
      monthlyForm.classList.add("hidden");
      weeklyForm.classList.remove("hidden");
      weeklyForm.classList.add("flex");
      addProductButton.dataset.plan = "Weekly";
    }
  }

  addExtra(newProductId, extraProducts, quantity) {
    extraProducts.forEach((element) => {
      const id = element.dataset.id;
      const productId = newProductId;
      const extraProductName =
        element.querySelector("select").options[
          element.querySelector("select").selectedIndex
        ].text;
      const extraProductCategory =
        element.querySelector("select").options[
          element.querySelector("select").selectedIndex
        ].parentElement.label;
      let newExtra;

      if (extraProductName !== "Selecciona un extra") {
        if (extraProductCategory === "Postres") {
          this.dessertExtraTemplateTarget.content.querySelector(
            ".extra-product-name"
          ).innerHTML = extraProductName;
          this.dessertExtraTemplateTarget.content.querySelector(
            ".product-quantity"
          ).innerHTML = quantity;
          newExtra = this.dessertExtraTemplateTarget.innerHTML.replace(
            /EXTRA_ID/g,
            id
          );
        } else {
          this.drinkExtraTemplateTarget.content.querySelector(
            ".extra-product-name"
          ).innerHTML = extraProductName;
          this.drinkExtraTemplateTarget.content.querySelector(
            ".product-quantity"
          ).innerHTML = quantity;
          newExtra = this.drinkExtraTemplateTarget.innerHTML.replace(
            /EXTRA_ID/g,
            id
          );
        }

        document
          .querySelector(`[data-product-card-id="${productId}"]`)
          .insertAdjacentHTML("beforeend", newExtra);
      }
    });
  }

  addDinner(productId, quantity) {
    this.dinnerExtraTemplateTarget.content.querySelector(
      ".product-quantity"
    ).innerHTML = quantity;

    const dinner = this.dinnerExtraTemplateTarget.innerHTML.replace(
      /EXTRA_ID/g,
      productId
    );

    document
      .querySelector(`[data-product-card-id="${productId}"]`)
      .insertAdjacentHTML("beforeend", dinner);
  }

  updateProduct(event) {
    const id = event.currentTarget.dataset.id;
    const target = document.querySelector(`[data-product-card-id="${id}"]`);

    target.parentNode.removeChild(target);
    this.addProduct(event);
  }

  removeProduct(event) {
    const target = event.currentTarget;
    const id = target.dataset.id;
    const formBlock = document.querySelector(`div [data-id="${id}"]`);

    if (target.classList.contains("delete-product-button")) {
      const productCard = document.querySelector(
        `[data-product-card-id="${id}"]`
      );
      productCard.parentNode.removeChild(productCard);
      formBlock.querySelector("input[name*='_destroy']").value = 1;
    } else {
      formBlock.parentNode.removeChild(formBlock);
    }
  }

  removeUnusedForms(event) {
    const id = event.currentTarget.dataset.addProductButtonId;
    const formBlock = document.querySelector(`div[data-id="${id}"]`);
    const hiddenForms = Array.from(formBlock.querySelectorAll("div.hidden"));

    hiddenForms.forEach((element) => {
      element.parentNode.removeChild(element);
    });
  }

  showCalendar(date, target) {
    if (date === "") {
      target.classList.add("hidden");
    } else {
      if (target.classList.contains("hidden")) {
        target.classList.remove("hidden");
      }
    }
  }
}
