import { Controller } from "stimulus"

export default class extends Controller {
 
  connect() {
    this.startRefresh()
  }
 
  disconnect () {
    this.stopRefresh()
  }

  startRefresh() { 
    this.timeoutID = setTimeout(() => window.location.reload(), 60000)
  }

  stopRefresh() { 
    clearTimeout(this.timeoutID)
  }
}
