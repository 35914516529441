import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["meals", "dinnerId", "includeDinner"];

  update(event) {
    this.includeDinnerTarget.value = !event.target.checked;
  }

  async get() {
    const meal = this.mealsTarget.options[this.mealsTarget.selectedIndex].label;
    const day = meal.split("|")[0];

    const dinner = await fetch(`/admin/orders/get_dinner?day=${day}`)
      .then((response) => response.json())
      .then((result) => result);

    this.dinnerIdTarget.value = dinner.productId;
  }
}
