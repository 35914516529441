import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["ourFoodInfo", "ourDeliveryInfo", "ourFoodButton", "ourDeliveryButton"]

  toggle(event) {
    const target = event.currentTarget
    
    if (target === this.ourFoodButtonTarget) {
      this.ourDeliveryButtonTarget.classList.remove("bg-primary", "text-white", "border-primary")
      this.ourDeliveryButtonTarget.classList.add("border-gray-300")
      this.ourFoodButtonTarget.classList.add("bg-primary", "text-white", "border-primary")
      this.ourFoodInfoTarget.classList.remove("hidden")
      this.ourDeliveryInfoTarget.classList.add("hidden")
    } else {
      this.ourFoodButtonTarget.classList.remove("bg-primary", "text-white", "border-primary")
      this.ourFoodButtonTarget.classList.add("border-gray-300")
      this.ourDeliveryButtonTarget.classList.add("bg-primary", "text-white", "border-primary")
      this.ourDeliveryInfoTarget.classList.remove("hidden")
      this.ourFoodInfoTarget.classList.add("hidden")
    }
  }
}