import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  toggleOrders(event) {
    const id = event.currentTarget.dataset.id
    const target = document.querySelector(`#order-${id}`)

    if (target.classList.contains("hidden")) {
      target.classList.remove("hidden")
      event.currentTarget.classList.add("ring-4", "ring-primary")
    } else {
      target.classList.add("hidden")
      event.currentTarget.classList.remove("ring-4", "ring-primary")
    }
  }

  orderDelivered(event) {
    const target = event.currentTarget
    const id = target.dataset.id
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");

    target.classList.add("bg-primary", "border-primary")
    target.dataset.action = ""
    
    fetch(`/riders/order_delivered`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        delivery_item: id,
        delivered: true
      })
    })

  }
}