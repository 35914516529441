import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "mealsSection",
    "dinnersSection",
    "extrasSection",
    "mealsOrders",
    "dinnersOrders",
    "extrasOrders",
    "mealsSummary",
    "dinnersSummary",
    "extrasSummary",
    "mealsButton",
    "dinnersButton",
    "extrasButton",
    "summaryButton",
    "mealsImage",
    "dinnersImage",
    "extrasImage",
    "orderModal",
  ];

  initialize() {
    if (this.hasMealsButtonTarget) {
      this.activeButton = this.mealsButtonTarget;
      this.activeSection = this.mealsSectionTarget;
      this.activeOrders = this.mealsOrdersTarget;
      this.activeSummary = this.mealsSummaryTarget;
      this.activeImage = this.mealsImageTarget;
    }
  }

  category(event) {
    const category = event.currentTarget.dataset.category;

    this.summaryButtonTarget.dataset.category = category;

    if (category === "meals") {
      this.toggleButton(this.mealsButtonTarget);
      this.toggleCategory(
        this.mealsSectionTarget,
        this.mealsOrdersTarget,
        this.mealsSummaryTarget
      );
      this.toggleImage(this.mealsImageTarget);
    } else if (category === "dinners") {
      this.toggleButton(this.dinnersButtonTarget);
      this.toggleCategory(
        this.dinnersSectionTarget,
        this.dinnersOrdersTarget,
        this.dinnersSummaryTarget
      );
      this.toggleImage(this.dinnersImageTarget);
    } else {
      this.toggleButton(this.extrasButtonTarget);
      this.toggleCategory(
        this.extrasSectionTarget,
        this.extrasOrdersTarget,
        this.extrasSummaryTarget
      );
      this.toggleImage(this.extrasImageTarget);
    }
  }

  summary(event) {
    const category = event.currentTarget.dataset.category;

    if (category === "meals") {
      this.toggleSummary(this.mealsOrdersTarget, this.mealsSummaryTarget);
    } else if (category === "dinners") {
      this.toggleSummary(this.dinnersOrdersTarget, this.dinnersSummaryTarget);
    } else {
      this.toggleSummary(this.extrasOrdersTarget, this.extrasSummaryTarget);
    }
  }

  toggleSummary(tableTarget, summaryTarget) {
    if (tableTarget.classList.contains("flex")) {
      tableTarget.classList.remove("flex");
      tableTarget.classList.add("hidden");
      summaryTarget.classList.remove("hidden");
      summaryTarget.classList.add("flex");
      this.summaryButtonTarget.classList.add("bg-black", "text-white");
      this.summaryButtonTarget.innerHTML = "Pedidos";
    } else {
      tableTarget.classList.remove("hidden");
      tableTarget.classList.add("flex");
      summaryTarget.classList.remove("flex");
      summaryTarget.classList.add("hidden");
      this.summaryButtonTarget.classList.remove("bg-black", "text-white");
      this.summaryButtonTarget.innerHTML = "Resumen";
    }
  }

  toggleButton(selectedButton) {
    this.activeButton.classList.remove("bg-black", "text-white");
    selectedButton.classList.add("bg-black", "text-white");
    this.activeButton = selectedButton;
  }

  toggleCategory(sectionTarget, ordersTarget, summaryTarget) {
    if (this.activeSection !== sectionTarget) {
      this.activeSection.classList.remove("flex");
      this.activeSection.classList.add("hidden");

      if (this.activeOrders.classList.contains("hidden")) {
        this.activeOrders.classList.remove("hidden");
        this.activeOrders.classList.add("flex");
      }

      if (this.activeSummary.classList.contains("flex")) {
        this.activeSummary.classList.remove("flex");
        this.activeSummary.classList.add("hidden");
        this.summaryButtonTarget.classList.remove("bg-black", "text-white");
        this.summaryButtonTarget.innerHTML = "Resumen";
      }

      sectionTarget.classList.remove("hidden");
      sectionTarget.classList.add("flex");

      this.activeSection = sectionTarget;
      this.activeOrders = ordersTarget;
      this.activeSummary = summaryTarget;
    }
  }

  toggleImage(imageTarget) {
    if (imageTarget !== this.activeImage) {
      imageTarget.classList.remove("hidden");
      imageTarget.classList.add("flex");
      this.activeImage.classList.remove("flex");
      this.activeImage.classList.add("hidden");
      this.activeImage = imageTarget;
    }
  }

  open() {
    this.orderModalTarget.classList.remove("invisible", "opacity-0");
    this.orderModalTarget.classList.add("opacity-1", "visible");
  }

  close() {
    this.orderModalTarget.classList.remove("opacity-1", "visible");
    this.orderModalTarget.classList.add("invisible", "opacity-0");
  }
}
