import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  open(event) {
    const id        = event.currentTarget.dataset.id
    const formBlock = document.querySelector(`form[data-id="${id}"]`)

    formBlock.classList.remove("invisible", "opacity-0")
    formBlock.classList.add("opacity-1", "visible")
  }

  close(event) {
    const id        = event.currentTarget.dataset.id
    const formBlock = document.querySelector(`form[data-id="${id}"]`)

    formBlock.classList.remove("opacity-1", "visible")
    formBlock.classList.add("invisible", "opacity-0")
  }

  toggleCancel(event) {
    const id           = event.currentTarget.dataset.id
    const button       = event.currentTarget
    const formBlock    = document.querySelector(`form[data-id="${id}"]`)
    const cancelInput  = formBlock.querySelector("#delivery_item_cancel")

    if (cancelInput.value === "true") {
      button.classList.remove("text-white", "bg-red-600")
      button.classList.add("text-red-600")
      button.textContent = "Cancelar Pedido"
      cancelInput.value  = false
    } else {
      button.classList.remove("text-red-600")
      button.classList.add("text-white", "bg-red-600")
      button.textContent = "Cancelado"
      cancelInput.value  = true
    }
  }

}