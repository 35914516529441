import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["lunchButton", "dinnerButton", "lunchMenu", "dinnerMenu"];

  connect() {
    console.log("Hello World");
  }

  toggle(event) {
    const target = event.currentTarget;

    if (target === this.lunchButtonTarget) {
      this.dinnerButtonTarget.classList.remove("bg-primary", "text-white");
      this.dinnerButtonTarget.classList.add("bg-white", "text-primary");
      this.dinnerMenuTarget.classList.remove("grid");
      this.dinnerMenuTarget.classList.add("hidden");
      this.lunchButtonTarget.classList.remove("bg-white", "text-primary");
      this.lunchButtonTarget.classList.add("bg-primary", "text-white");
      this.lunchMenuTarget.classList.remove("hidden");
      this.lunchMenuTarget.classList.add("grid");
    } else {
      this.lunchButtonTarget.classList.remove("bg-primary", "text-white");
      this.lunchButtonTarget.classList.add("bg-white", "text-primary");
      this.lunchMenuTarget.classList.remove("grid");
      this.lunchMenuTarget.classList.add("hidden");
      this.dinnerButtonTarget.classList.remove("bg-white", "text-primary");
      this.dinnerButtonTarget.classList.add("bg-primary", "text-white");
      this.dinnerMenuTarget.classList.remove("hidden");
      this.dinnerMenuTarget.classList.add("grid");
    }
  }
}
