import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["cartModal", 
                    "menuModal", 
                    "orderItem", 
                    "orderBlock", 
                    "priceBlock",
                    "cartItems",
                    "orderSubtotal",
                    "orderTotal"]
  
  open(event) {
    const body = document.querySelector("body")

    if (event.currentTarget.dataset.id === "menu") {
      this.menuModalTarget.classList.remove("opacity-0", "invisible")
      this.menuModalTarget.classList.add("opacity-1", "visible")
    } else {
      this.cartModalTarget.classList.remove("opacity-0", "invisible")
      this.cartModalTarget.classList.add("opacity-1", "visible")
    }
    body.classList.add("overflow-hidden")
  }

  close(event) {
    const body = document.querySelector("body")

    if (event.currentTarget.dataset.id === "menuClose") {
      this.menuModalTarget.classList.remove("opacity-1", "visible")
      this.menuModalTarget.classList.add("opacity-0", "invisible")
    } else {
      this.cartModalTarget.classList.remove("opacity-1", "visible")
      this.cartModalTarget.classList.add("opacity-0", "invisible")
    }
    body.classList.remove("overflow-hidden")
  }

  removeOrderItem(event) {
    const id = event.currentTarget.dataset.id

    this.orderItemTargets.forEach(element => {
      if (element.dataset.id === id) { 
        element.parentNode.removeChild(element)
        if (element.dataset.productId !== "") { this.removeCheckItem(element.dataset.productId) }
      }
    })

    if (this.orderItemTargets.length == 0) { 
      this.emptyOrder()
    } else {
      this.updateTotal()
    }

    this.updateCartItemsCount()
  }

  emptyOrder() {
    const content = `
                    <div class="mt-20 text-center">
                      <p class="text-lg mb-3">Your order is empty.</p>
                      <a class="text-4xl font-bold uppercase text-primary hover:text-black" href="/how-it-works">let's get started!</a>
                    </div>
                    `
    this.orderBlockTarget.insertAdjacentHTML("afterend", content)
    this.priceBlockTarget.parentNode.removeChild(this.priceBlockTarget)
    Array.from(document.querySelectorAll(".next-button")).forEach(element => { 
      element.classList.add("opacity-40", "cursor-default") 
      element.dataset.action = ""
    })
  }

  removeCheckItem(id) {
    const target = document.querySelector(`div[data-product-id="${id}"]`)
    
    if (target !== null) {
      const content = `
                      <a class="flex w-2/12 items-center justify-center p-4 rounded-tl-lg rounded-br-lg bg-primary" href="/products/${target.dataset.slug}">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="#FFF">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                        </svg>
                      </a>
                      `
      target.parentNode.insertAdjacentHTML("beforeend", content)
      target.parentNode.removeChild(target)
    }
  }

  increaseQuantity(event) {
    const id = event.currentTarget.dataset.id
    const orderItemBlock = document.querySelector(`div[data-id="${id}"]`)
    const orderItemExtras = Array.from(orderItemBlock.querySelectorAll(".extra-item"))
    let orderItemQuantity = parseInt(orderItemBlock.dataset.quantity)
  
    if (orderItemQuantity === 1 ) { 
      orderItemBlock.querySelector("#decrease_button").dataset.action = "click->cart#decreaseQuantity"
      orderItemBlock.querySelector("#decrease_button").classList.add("cursor-pointer")
    }
    
    if (orderItemQuantity < 20 ) {
      orderItemQuantity += 1
      orderItemBlock.querySelector("#order_item_quantity").innerText = orderItemQuantity
      orderItemBlock.dataset.quantity = orderItemQuantity

      orderItemExtras.forEach(element => {
        this.updateExtra(element, orderItemQuantity)
      })

      if (orderItemQuantity === 20) { 
        orderItemBlock.querySelector("#increase_button").dataset.action = ""
        orderItemBlock.querySelector("#increase_button").classList.remove("cursor-pointer")
      }
    }
    
    this.updateOrderItem(id, orderItemQuantity)
    this.updateCartItemsCount()
  }

  decreaseQuantity(event) {
    const id = event.currentTarget.dataset.id
    const orderItemBlock = document.querySelector(`div[data-id="${id}"]`)
    const orderItemExtras = Array.from(orderItemBlock.querySelectorAll(".extra-item"))
    let orderItemQuantity = parseInt(orderItemBlock.dataset.quantity)

    if (orderItemQuantity === 20 ) { 
      orderItemBlock.querySelector("#increase_button").dataset.action = "click->cart#increaseQuantity"
      orderItemBlock.querySelector("#increase_button").classList.add("cursor-pointer")
    }
    
    if (orderItemQuantity > 1 ) {
      orderItemQuantity -= 1
      orderItemBlock.querySelector("#order_item_quantity").innerText = orderItemQuantity
      orderItemBlock.dataset.quantity = orderItemQuantity

      orderItemExtras.forEach(element => {
        this.updateExtra(element, orderItemQuantity)
      })

      if (orderItemQuantity === 1) { 
        orderItemBlock.querySelector("#decrease_button").dataset.action = ""
        orderItemBlock.querySelector("#decrease_button").classList.remove("cursor-pointer")
      }
    }
    this.updateOrderItem(id, orderItemQuantity)
    this.updateCartItemsCount()
  }

  updateOrderItem(id, quantity) {
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    
    fetch(`/order_items/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        quantity: quantity
      })
    })
    
    this.updateSubtotal(id, quantity)
    this.updateTotal()
  }

  updateExtra(extra, quantity) {
    const extraSubtotal = extra.querySelector("#extra-subtotal")
    const extraQuantity = extra.querySelector("#extra-quantity")
    const subtotal = (parseInt(extra.dataset.price) * quantity / parseFloat(100)).toFixed(2).replace(".",",")

    extraSubtotal.innerText = subtotal
    extraQuantity.innerText = quantity
  }

  updateSubtotal(id, quantity) {
    const target = document.querySelector(`div[data-id="${id}"]`)
    const orderItemSubtotal = target.querySelector("#order_item_subtotal")
    const subtotal = parseInt(target.dataset.subtotal)
    
    orderItemSubtotal.innerText = (subtotal * quantity / parseFloat(100)).toFixed(2).replace(".", ",")
  }

  updateTotal() {
    let subtotal = 0     
    
    this.orderItemTargets.forEach(element => {
      const quantity = parseInt(element.dataset.quantity)
      const orderItemExtras = Array.from(element.querySelectorAll(".extra-item"))
      
      subtotal += (parseInt(element.dataset.subtotal) * quantity)

      orderItemExtras.forEach(element => {
        subtotal += (parseInt(element.dataset.price) * quantity)
      })
    })
    
    subtotal = (subtotal / parseFloat(100)).toFixed(2).replace(".", ",")
    
    this.orderSubtotalTarget.innerHTML = subtotal
    this.orderTotalTarget.innerHTML = subtotal
  }

  updateCartItemsCount() {
    let totalCartItems = 0

    this.orderItemTargets.forEach(element => totalCartItems += parseInt(element.dataset.quantity))
    
    if (totalCartItems === 0) {
      this.cartItemsTarget.parentNode.parentNode.removeChild(this.cartItemsTarget.parentNode)
    } else {
      this.cartItemsTarget.innerHTML = totalCartItems
    }
  }
}