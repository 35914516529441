import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "extrasView",
    "dessertsExtras",
    "dinnerExtras",
    "dessertsButton",
    "dinnerButton",
    "confirm",
  ];

  toggle(event) {
    const target = event.currentTarget;

    if (target === this.dinnerButtonTarget) {
      this.dessertsButtonTarget.classList.remove("bg-primary", "text-white");
      this.dessertsButtonTarget.classList.add("text-primary");
      this.dinnerButtonTarget.classList.remove("text-primary");
      this.dinnerButtonTarget.classList.add("bg-primary", "text-white");
      this.dinnerExtrasTarget.classList.remove("hidden");
      this.dinnerExtrasTarget.classList.add("grid");
      this.dessertsExtrasTarget.classList.remove("grid");
      this.dessertsExtrasTarget.classList.add("hidden");
    } else {
      this.dinnerButtonTarget.classList.remove("bg-primary", "text-white");
      this.dinnerButtonTarget.classList.add("text-primary");
      this.dessertsButtonTarget.classList.remove("text-primary");
      this.dessertsButtonTarget.classList.add("bg-primary", "text-white");
      this.dessertsExtrasTarget.classList.remove("hidden");
      this.dessertsExtrasTarget.classList.add("grid");
      this.dinnerExtrasTarget.classList.remove("grid");
      this.dinnerExtrasTarget.classList.add("hidden");
    }
  }

  addExtra(event) {
    const id = event.currentTarget.dataset.id;
    const formBlock = document.querySelector(`div[data-extra-id="${id}"]`);
    const checkButton = formBlock.querySelector("#check_button");
    const productId = formBlock.querySelector("input[type='hidden']");

    productId.value = id;

    event.currentTarget.classList.remove("flex");
    event.currentTarget.classList.add("hidden");
    checkButton.classList.remove("hidden");
    checkButton.classList.add("flex");
  }

  removeExtra(event) {
    const id = event.currentTarget.dataset.id;
    const formBlock = document.querySelector(`div[data-extra-id="${id}"]`);
    const addButton = formBlock.querySelector(`button[data-id="${id}"]`);
    const productId = formBlock.querySelector("input[type='hidden']");

    productId.value = "";

    event.currentTarget.classList.remove("flex");
    event.currentTarget.classList.add("hidden");
    addButton.classList.remove("hidden");
    addButton.classList.add("flex");
  }

  checkout() {
    this.confirmTarget.classList.remove("opacity-0", "invisible");
    this.confirmTarget.classList.add("opacity-1", "visible");
  }

  back() {
    this.extrasViewTarget.classList.remove("opacity-1", "visible");
    this.extrasViewTarget.classList.add("opacity-0", "invisible");
  }

  close() {
    this.confirmTarget.classList.remove("opacity-1", "visible");
    this.confirmTarget.classList.add("opacity-0", "invisible");
  }
}
