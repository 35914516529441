import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "quantity",
    "supplementPrice",
    "total",
    "sizeSelect",
    "proteinSelect",
    "customSelect",
    "extraVeggiesButton",
    "extraProteinButton",
    "extraCarbsButton",
    "extrasView",
    "extraPrice",
    "dinnerPrice",
  ];

  initialize() {
    if (this.hasSizeSelectTarget) {
      this.mealSize = parseInt(
        this.sizeSelectTarget.options[this.sizeSelectTarget.selectedIndex]
          .dataset.price
      );
      this.mealProtein = parseInt(
        this.proteinSelectTarget.options[this.proteinSelectTarget.selectedIndex]
          .dataset.price
      );
      this.mealExtraVeggies = parseInt(
        this.extraVeggiesButtonTarget.dataset.price
      );
      this.mealExtraProtein = parseInt(
        this.extraProteinButtonTarget.dataset.price
      );
      this.mealExtraCarbs = parseInt(this.extraCarbsButtonTarget.dataset.price);
      this.mealCustom = parseInt(
        this.customSelectTarget.options[this.customSelectTarget.selectedIndex]
          .dataset.price
      );
      this.orderItemQuantity = parseInt(this.quantityTarget.innerText);
      this.supplementPrice = parseInt(this.supplementPriceTarget.value);
      this.calcTotal();
    } else {
      this.productPrice = parseInt(this.totalTarget.dataset.price);
      this.orderItemQuantity = parseInt(this.quantityTarget.innerText);
    }
  }

  toggleExtraVeggies(event) {
    const price = parseInt(event.currentTarget.dataset.price);
    const value = event.currentTarget.dataset.value;
    const orderItemExtraVeggies = document.querySelector(
      "#order_item_meal_extra_veggies"
    );

    this.extraVeggiesButtonTargets.forEach((element) => {
      element.classList.remove("bg-primary");
    });
    event.currentTarget.classList.add("bg-primary");

    orderItemExtraVeggies.value = value;
    this.mealExtraVeggies = price;
    this.calcTotal();
  }

  toggleExtraProtein(event) {
    const price = parseInt(event.currentTarget.dataset.price);
    const value = event.currentTarget.dataset.value;
    const orderItemExtraProtein = document.querySelector(
      "#order_item_meal_extra_protein"
    );

    this.extraProteinButtonTargets.forEach((element) => {
      element.classList.remove("bg-primary");
    });
    event.currentTarget.classList.add("bg-primary");

    orderItemExtraProtein.value = value;
    this.mealExtraProtein = price;
    this.calcTotal();
  }

  toggleExtraCarbs(event) {
    const price = parseInt(event.currentTarget.dataset.price);
    const value = event.currentTarget.dataset.value;
    const orderItemExtraCarbs = document.querySelector(
      "#order_item_meal_extra_carbs"
    );

    this.extraCarbsButtonTargets.forEach((element) => {
      element.classList.remove("bg-primary");
    });
    event.currentTarget.classList.add("bg-primary");

    orderItemExtraCarbs.value = value;
    this.mealExtraCarbs = price;
    this.calcTotal();
  }

  selectSize() {
    const price = parseInt(
      this.sizeSelectTarget.options[this.sizeSelectTarget.selectedIndex].dataset
        .price
    );

    this.mealSize = price;
    this.calcTotal();
  }

  selectProtein() {
    const proteinType =
      this.proteinSelectTarget.options[this.proteinSelectTarget.selectedIndex]
        .value;
    const price = parseInt(
      this.proteinSelectTarget.options[this.proteinSelectTarget.selectedIndex]
        .dataset.price
    );

    if (proteinType === "normal") {
      this.supplementPrice = parseInt(this.supplementPriceTarget.value);
    } else {
      this.supplementPrice = 0;
    }

    this.mealProtein = price;
    this.calcTotal();
  }

  selectCustom() {
    const price = parseInt(
      this.customSelectTarget.options[this.customSelectTarget.selectedIndex]
        .dataset.price
    );

    this.mealCustom = price;
    this.calcTotal();
  }

  add() {
    const orderItemQuantity = document.querySelector(
      "input[type='hidden'][name='order_item[quantity]']"
    );
    if (this.orderItemQuantity < 20) {
      this.orderItemQuantity = this.orderItemQuantity + 1;
      this.quantityTarget.innerText = this.orderItemQuantity;
      orderItemQuantity.value = this.orderItemQuantity;
      this.calcTotal();
      this.updateExtrasPrices();
    }
  }

  remove() {
    const orderItemQuantity = document.querySelector(
      "input[type='hidden'][name='order_item[quantity]']"
    );

    if (this.orderItemQuantity > 1) {
      this.orderItemQuantity = this.orderItemQuantity - 1;
      this.quantityTarget.innerText = this.orderItemQuantity;
      orderItemQuantity.value = this.orderItemQuantity;
      this.calcTotal();
      this.updateExtrasPrices();
    }
  }

  open() {
    this.extrasViewTarget.classList.remove("opacity-0", "invisible");
    this.extrasViewTarget.classList.add("opacity-1", "visible");
  }

  calcTotal() {
    const orderItemSubtotal = document.querySelector(
      "#order_item_subtotal_cents"
    );
    let subtotal;

    if (this.productPrice) {
      subtotal = this.productPrice;
    } else {
      subtotal =
        this.supplementPrice +
        this.mealSize +
        this.mealProtein +
        this.mealExtraVeggies +
        this.mealExtraProtein +
        this.mealExtraCarbs +
        this.mealCustom;
    }

    orderItemSubtotal.value = subtotal;

    if (this.hasDinnerPriceTarget) {
      this.updateDinnerPrice(subtotal);
    }

    if (window.innerWidth >= 1024) {
      this.totalTargets[1].innerText = (
        (subtotal * this.orderItemQuantity) /
        parseFloat(100)
      )
        .toFixed(2)
        .replace(".", ",");
    } else {
      this.totalTargets[0].innerText = (
        (subtotal * this.orderItemQuantity) /
        parseFloat(100)
      )
        .toFixed(2)
        .replace(".", ",");
    }
  }

  updateExtrasPrices() {
    this.extraPriceTargets.forEach((element) => {
      let price;
      price = (
        (element.parentNode.dataset.extraPrice * this.orderItemQuantity) /
        parseFloat(100)
      )
        .toFixed(2)
        .replace(".", ",");
      element.innerHTML = price;
    });
  }

  updateDinnerPrice(subtotal) {
    this.dinnerPriceTarget.innerText = (
      (subtotal * this.orderItemQuantity) /
      parseFloat(100)
    )
      .toFixed(2)
      .replace(".", ",");
  }
}
