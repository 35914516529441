import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "bumerangInfo", 
                     "orderTotal", 
                     "checkedButton", 
                     "discountLabel", 
                     "discountField",
                     "applyButton",
                     "availableInfo"]

  open() {
    this.bumerangInfoTarget.classList.remove("opacity-0", "invisible")
    this.bumerangInfoTarget.classList.add("opacity-1", "visible")
  }

  close() {
    this.bumerangInfoTarget.classList.remove("opacity-1", "visible")
    this.bumerangInfoTarget.classList.add("opacity-0", "invisible")
  }

  continue() {
    this.availableInfoTarget.classList.remove("opacity-1", "visible")
    this.availableInfoTarget.classList.add("opacity-0", "invisible")
  }

  checkDiscount(event) {
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    const target = event.currentTarget
    const id = target.dataset.id
    const input = document.querySelector("#discount")
    const code = input.value

    fetch(`/orders/${id}/validate_discount`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        discount_code: code
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.valid === true) {
        this.applyDiscount(target, input, data.amount)
      } else {
        this.invalidDiscount(input, data.msg)
      }
    })
  }
  
  invalidDiscount(input, message) {
    this.discountLabelTarget.classList.add("text-red-600")
    this.discountLabelTarget.innerText = message
    input.value = ""
  }
  
  applyDiscount(target, input, amount) {
    const discountAmount = `€ ${(amount / parseFloat(100)).toFixed(2).replace(".", ",")} EUR`
    const content = `
                    <div class="flex mb-3" id="discount-block">
                      <div class="w-2/3 2xl:text-xl">
                        <p>${input.value}</p>
                      </div>
                      <div class="w-1/3 text-right 2xl:text-xl">
                        <p>- ${discountAmount}</p>
                      </div>
                    </div>
                    ` 
    this.orderTotalTarget.insertAdjacentHTML("beforebegin", content)
    
    target.classList.add("hidden")
    this.checkedButtonTarget.classList.remove("hidden")
    this.checkedButtonTarget.classList.add("flex")
    this.discountLabelTarget.classList.add("text-newGreen")
    this.discountLabelTarget.innerText = "Valid discount"
    input.readOnly = true
    this.discountFieldTarget.value = input.value
    this.calcTotal(amount)
  }

  removeDiscount() {
    const input = document.querySelector("#discount")
    const discountBlock = document.querySelector("#discount-block")
    const total = (parseInt(this.orderTotalTarget.dataset.total) / parseFloat(100)).toFixed(2).replace(".",",")
    
    this.checkedButtonTarget.classList.remove("flex")
    this.checkedButtonTarget.classList.add("hidden")
    this.applyButtonTarget.classList.remove("hidden")
    this.discountLabelTarget.classList.remove("text-newGreen", "text-red-600")
    this.discountLabelTarget.innerText = "Do you have a discount code?"
    this.discountFieldTarget.value = ""
    discountBlock.parentNode.removeChild(discountBlock)
    this.orderTotalTarget.querySelector("#total-amount").innerHTML = total
    input.readOnly = false
    input.value = ""
  }

  calcTotal(amount) {
    const discountAmount = amount
    let total = parseInt(this.orderTotalTarget.dataset.total)

    total -= discountAmount
    this.orderTotalTarget.querySelector("#total-amount").innerHTML = (total / parseFloat(100)).toFixed(2).replace(".",",")
  }
}