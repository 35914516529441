import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "allergensInfo",
    "veganLabel",
    "veganInput",
    "supplementInput",
    "priceInput",
    "priceAlert",
    "productsTable",
    "archivedTable",
    "archivedButton",
  ];

  initialize() {
    this.hiddenArchived = true;
  }

  disable(event) {
    const target = event.currentTarget;

    if (target.dataset.name === "meals") {
      this.supplementInputTarget.classList.remove("hidden");
      this.supplementInputTarget.classList.add("flex");
      this.priceInputTarget.classList.remove("flex");
      this.priceInputTarget.classList.add("hidden");
      this.veganLabelTarget.innerText = "Opción vegana *";
      this.veganInputTarget.disabled = false;
      this.veganInputTarget.required = true;
    } else {
      this.supplementInputTarget.classList.add("hidden");
      this.priceInputTarget.classList.remove("hidden");
      this.priceInputTarget.classList.add("flex");

      this.veganLabelTarget.innerText = "Opción vegana";
      this.veganInputTarget.disabled = true;
      this.veganInputTarget.required = false;
    }

    if (target.dataset.name === "drinks") {
      this.allergensInfoTarget.classList.remove("flex");
      this.allergensInfoTarget.classList.add("hidden");
    } else {
      this.allergensInfoTarget.classList.remove("hidden");
      this.allergensInfoTarget.classList.add("flex");
    }
  }

  toggleArchived() {
    this.hiddenArchived = !this.hiddenArchived;
    this.archivedTableTarget.hidden = this.hiddenArchived;

    if (this.hiddenArchived) {
      this.archivedButtonTarget.classList.remove("bg-white");
      this.archivedButtonTarget.classList.add("bg-primary");
      this.archivedButtonTarget.textContent = "Ver Archivados";
      this.productsTableTarget.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    } else {
      this.archivedButtonTarget.classList.remove("bg-primary");
      this.archivedButtonTarget.classList.add("bg-white");
      this.archivedButtonTarget.textContent = "Ocultar Archivados";
      this.archivedTableTarget.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }
}
