import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["nutritionalInfo", "additionalInfo", "nutritionalButton", "additionalButton"]

  toggle(event) {
    const target = event.currentTarget
    
    if (target === this.nutritionalButtonTarget) {
      this.additionalButtonTarget.classList.remove("bg-secondary")
      this.nutritionalButtonTarget.classList.add("bg-secondary")
      this.nutritionalInfoTarget.classList.remove("hidden")
      this.additionalInfoTarget.classList.add("hidden")
    } else {
      this.nutritionalButtonTarget.classList.remove("bg-secondary")
      this.additionalButtonTarget.classList.add("bg-secondary")
      this.additionalInfoTarget.classList.remove("hidden")
      this.nutritionalInfoTarget.classList.add("hidden")
    }
  }
}