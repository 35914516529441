import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["stickersCount", "totalMeals", "stickerModal"];

  initialize() {
    this.checkboxes = Array.from(
      document.querySelectorAll("input[type='checkbox']")
    );
    if (this.hasStickersCountTarget) {
      this.count = parseInt(this.stickersCountTarget.innerText);
    }
    if (this.hasTotalMealsTarget) {
      this.totalMeals = parseInt(this.totalMealsTarget.innerText);
    }
  }

  stickersCount() {
    let count = 0;

    this.checkboxes.forEach((element) => {
      if (element.checked) {
        count += 1;
      }
    });

    this.stickersCountTarget.innerText = count;
    if (this.hasTotalMealsTarget) {
      this.totalMealsTarget.innerText = this.totalMeals - count;
    }
  }

  selectAll(event) {
    const target = event.currentTarget;

    this.checkboxes.forEach((element) => (element.checked = true));
    target.classList.add("bg-black", "font-bold", "text-white");
    target.dataset.action = "stickers#unSelectAll";
    this.stickersCount();
  }

  unSelectAll(event) {
    const target = event.currentTarget;

    this.checkboxes.forEach((element) => (element.checked = false));
    target.classList.remove("bg-black", "font-bold", "text-white");
    target.dataset.action = "stickers#selectAll";
    this.stickersCount();
  }

  showModal(event) {
    event.preventDefault();
    this.stickerModalTarget.classList.remove("opacity-0", "invisible");
    this.stickerModalTarget.classList.add("opacity-1", "visible");
  }

  hideModal() {
    this.stickerModalTarget.classList.remove("opacity-1", "visible");
    this.stickerModalTarget.classList.add("opacity-0", "invisible");
  }
}
